import React, {useRef} from 'react';
import emailjs from "@emailjs/browser";
import images from '../../constants/images';
import Info from '../../components/Info/Info';
import "./Contact.css";

const Contact = () => {
    const form = useRef();


    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_k0o7yee",
                "template_0soo7n8",
                form.current,
                "MZqAJdbhJfw7yLeam"
            )
            .then((result) => {
                console.log(result.text);
                console.log("Message send")
            }, (error) => {
                console.log(error.text);
            })
            .then(message => alert("Wij hebben uw gegevens ontvangen en wensen u een Fijne Dag toe!"))
    };

    return (
    <section className='contact section-p-top bg-black ' id = "contact">
        <div className='container '>
            <div className='contact-content grid text-center '>
                <div className='contact-left '>
                    <div className='section-t '>
                        <h3>Contact</h3>
                        {/*<p className='text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. */}
                        {/*    Sit, assumenda quia repellendus architecto culpa nisi?</p>*/}
                    </div>
                    <div className="contact-form-container">
                    <form className='contact ' ref={form} onSubmit={sendEmail}>
                        <div className="contact">
                            <div className="contact contact-content">

                                <div className="form-control-text">
                                    {/*<div className="voornaam">*/}
                                    {/*    <label>*/}
                                    {/*        <input*/}
                                    {/*            type="text"*/}
                                    {/*            name="voornaam"*/}
                                    {/*            placeholder="Voornaam"*/}
                                    {/*            required*/}
                                    {/*        />*/}

                                    {/*    </label>*/}
                                    {/*</div>*/}
                                    {/*<div className="achternaam">*/}
                                    {/*    <label>*/}
                                    {/*        <input*/}
                                    {/*            type="text"*/}
                                    {/*            name="achternaam"*/}
                                    {/*            placeholder="Achternaam"*/}
                                    {/*            required*/}
                                    {/*        />*/}
                                    {/*    </label>*/}
                                    {/*</div>*/}
                                    <div className="bedrijfsnaam">
                                        <label>
                                            <input
                                                type="text"
                                                name="bedrijfsnaam"
                                                placeholder="Bedrijfsnaam"
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div className="straatnaam">
                                        <label>
                                            <input
                                                type="text"
                                                name="straatnaam"
                                                placeholder="Straatnaam"
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div className="huisnummer">
                                        <label>
                                            <input
                                                type="text"
                                                name="huisnummer"
                                                placeholder="Huisnummer"
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div className="postcode">
                                        <label>
                                            <input
                                                type="text"
                                                name="postcode"
                                                placeholder="Postcode"
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div className="woonplaats">
                                        <label>
                                            <input
                                                type="text"
                                                name="woonplaats"
                                                placeholder="Woonplaats"
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div className="e-mailadres">
                                        <label>
                                            {/*<AiOutlineMail className="icons" size={22}/>*/}
                                            <input
                                                type="email"
                                                name="mail"
                                                placeholder="E-mailadres"
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div className="telefoonnummer">
                                        <label>
                                            {/*<HiPhone className="icons" size={22}/>*/}
                                            <input
                                                type="text"
                                                name="telefoonnummer"
                                                placeholder="Telefoonnummer"
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div className="bericht">
                                        <label>
                                                <textarea
                                                    name="bericht"
                                                    rows={4}
                                                    placeholder="Bericht"
                                                    required
                                                />
                                        </label>
                                    </div>
                                    <button type="submit" className="submit-btn">Verstuur</button>
                                </div>
                            </div>
                        </div>

                    </form>
                    </div>
                </div>

                <div className='contact-right'>
                    <img src = {images.form_main_img} alt = "" />
                </div>
            </div>
        </div>

        {/*<Map />*/}
        <Info />
    </section>
  )
}

export default Contact
