import React from "react";
import sections from "../../constants/data";
import "./Vacature.css"

const Vacature = () => {
  return (
    <section className='portfolio section-p bg-dark' id = "portfolio">
        <div className='container'>
            <div className='portfolio-content'>
                <div className='section-t text-center'>
                    <h3>Vacature</h3>
                    <br/>

                    <h6 className='text'>
                        Ben jij klaar om deel uit te maken van een innovatief team dat werkt aan de toekomst van connectiviteit? Bij Connect The
                        World zijn we op zoek naar gemotiveerde professionals die samen met ons Nederland, België en Duitsland willen verbinden met
                        betrouwbare en snelle glasvezelnetwerken. Of je nu een ervaren glasvezelmonteur bent of juist aan het begin staat van je technische carrière,
                        wij hebben uitdagende mogelijkheden voor jou. Samen zorgen we ervoor dat onze klanten kunnen rekenen op stabiele en hoogwaardige verbindingen.
                        Ontdek onze vacatures en bouw mee aan de digitale toekomst!
                    </h6>
                    <br/>
                    <h3 className='fs-26 fw-4 text-robin-blue text-center'>
                        Waarom werken bij Connect The World?
                    </h3>
                    <br/>
                    <h6 className='text'>
                        Bij ons werk je niet alleen aan technologie, maar aan oplossingen die het leven van onze klanten verbeteren.
                        Wij geloven in kwaliteit, innovatie, en toewijding, en zijn vastbesloten om onze klanten te voorzien van stabiele en hoogwaardige verbindingen.
                        Je komt terecht in een team dat staat voor samenwerking, kennisdeling, en het behalen van successen samen.
                    </h6>
                    <br/>
                    <h3 className='fs-26 fw-4 text-robin-blue text-center'>
                        Wat bieden wij jou?
                    </h3>
                    <h6 className='text'>
                        Naast uitdagende projecten en de mogelijkheid om jezelf te ontwikkelen, bieden wij een stimulerende werkomgeving waarin jouw ideeën
                        en inbreng worden gewaardeerd. Je zult samenwerken met collega’s die dezelfde passie voor technologie delen en die net als jij geloven in het belang van connectiviteit.
                        Wij investeren in jouw groei door middel van opleidingen, certificeringen en persoonlijke begeleiding.
                    </h6>
                    <h6 className='text'>
                        Sluit je aan bij ons team en bouw mee aan een duurzame digitale toekomst. Ontdek onze vacatures en maak deel uit van een organisatie die de normen verlegt in glasvezeltechnologie.
                        Samen zorgen we ervoor dat onze klanten kunnen rekenen op topkwaliteit en betrouwbare verbindingen.
                    </h6>
                    <br/>
                    <h3 className='fs-26 fw-4 text-robin-blue text-center'>
                        Sta jij klaar om mee te groeien in een wereld vol mogelijkheden?
                    </h3>
                    <h6 className='text'>
                        Solliciteer
                        vandaag nog en zet de eerste stap naar een veelbelovende carrière bij Connect The World!
                        We staan altijd open voor talent en ontvangen graag een open sollicitatie.
                        Stuur je motivatie en CV naar <strong><a href="mailto:info@connecttheworld.nl">info@connecttheworld.nl</a></strong>, en wie weet vinden we samen de perfecte rol voor jou.
                    </h6>
                </div>

                {/*<div className='item-list text-center text-white grid'>*/}
                {/*    {*/}
                {/*        sections.portfolio.map(portfolio => {*/}
                {/*            return (*/}
                {/*                <div className='item flex flex-center flex-column translate-effect' key = {portfolio.id} style = {{*/}
                {/*                    background: `url(${portfolio.image})`*/}
                {/*                }}>*/}
                {/*                    <div className='item-title fs-25 fw-6'>{portfolio.title}</div>*/}
                {/*                    <div className='text text-white'>{portfolio.text}</div>*/}
                {/*                </div>*/}
                {/*            )*/}
                {/*        })*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        </div>
    </section>
  )
}

export default Vacature
