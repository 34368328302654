import React from 'react';
import "./Features.css";
import sections from '../../constants/data';
import SingleFeatures from './SingleFeatures';

const Features = () => {
  return (
    <section className='features section-p bg-black' id = "features">
        <div className='container'>

            <h3 className='fs-26 fw-4 text-robin-blue text-center'>Veelgestelde Vragen</h3>
            <div className='features-content'>
                <div className='item-list text-white'>

                    {
                        sections.features.map(feature => {
                            return (
                                <SingleFeatures key = {feature.id} {...feature} />
                            )
                        })
                    }
                </div>
            </div>
            <h6 className='fs-20 fw-4 text-grey text-center'>Als u nog andere vragen heeft of meer informatie wilt, neem dan gerust contact met ons op. We zijn hier om u te helpen!</h6>
        </div>
    </section>
  )
}

export default Features
