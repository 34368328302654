import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { useTypewriter } from "react-simple-typewriter";
import Aos from "aos";
import "aos/dist/aos.css";
import Background1 from "../../assets/ConnectTheWorldFaster.mp4";
import "./Home.css";


function Home() {
    useEffect(() => {
        Aos.init({duration: 2000})
    }, [])

    const [text] = useTypewriter({
        words: ["NEDERLAND", "Duitsland", "Luxemburg", "België"],
        loop: true,
        typeSpeed: 20,
        deleteSpeed: 10,
        delaySpeed: 2000,
    });


    return (
        <>
            <div className="home">
                <div className="home section">
                    <div className="secContainer">
                        <video src={Background1} autoPlay playsInline={true} muted typeof='mov'/>
                        {/*// om looping van de video te stoppen heb ik loop uit de code gehaald*/}
                        {/*// orignele is <video src={Background1} autoPlay playsInline={true} loop muted typeof='mov'/>*/}

                        <div className="textDiv">

                            <span className="title1">
                                CONNECT THE WORLD
                            </span>
                            <br/>

                            {/*<span data-aos='fade-up' data-aos-duration='2000' className="title">*/}
                            {/*    Met <strong>Veel Ervaring in: <>{text}</> </strong>*/}
                            {/*</span>*/}

                            {/*<span className="title">*/}
                            {/*    De specialist in slimme internet oplossingen*/}
                            {/*</span>*/}

                            <span data-aos='fade-up' data-aos-duration='3000' className="title">
                                DE SPECIALIST IN SLIMME INTERNET OPLOSSINGEN
                            </span>

                            {/*<div data-aos='fade-up' data-aos-duration='3000' className="input_btn flex">*/}
                            {/*    <Link to="/contact">*/}
                            {/*        <button type="submit" className="btn">Vraag Offerte Aan</button>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
