import React from 'react';
import images from '../../constants/images';
import Qualities from "../../components/Qualities/Qualities";
import Services from "../../components/Services/Services";
import Features from "../../components/Features/Features";
import "./About.css";



const About = () => {
  return (
      <div>
          <section className='about section-p bg-dark' id="about">
              <div className='container'>
                  <div className='about-content grid text-center'>
                      <div className="content-left">
                          <img src={images.about_main_img} alt=""/>

                      </div>
                      <div className='content-right'>
                          <div className='section-t'>
                              <h3 className='fs-24'>Welkom bij Connect The World!</h3>
                          </div>
                          <p className="text">Bij Connect The World zijn we gepassioneerd over het bouwen van snelle,
                              toekomstbestendige en betrouwbare glasvezel netwerken voor onze klanten. Met expertise in
                              het aanleggen van verschillende soorten netwerken in huizen en kantoren, zijn we uw
                              one-stop-bestemming voor al uw netwerkbehoeften.</p>
                          <p className='text'>Naast het bieden van de beste diensten voor praktische oplossingen in heel
                              Europa, produceren we ook al onze producten in Duitsland voor de best mogelijke
                              kwaliteit.</p>
                          <p className='text'>U kunt onze producten vinden op www.ctwshop.eu</p>
                      </div>
                  </div>
              </div>
          </section>
          <Qualities/>


      </div>
  )
}

export default About
